import React from "react";
import { Link, NavLink } from "react-router-dom";
import "../styles/nav.css";

export default function Nav() {
  return (
    <header>
      {/* <Link to="/" id="nav-title">
        Julius & Holly Miles
      </Link>
      <nav>
        <ul>
          <li>
            <NavLink to="/">Hem</NavLink>
          </li>
          <li>
            <NavLink to="/rsvp">RSVP</NavLink>
          </li>
          <li>
            <NavLink to="/info">Info</NavLink>
          </li>
          <li>
            <NavLink to="/historia">Vår historia</NavLink>
          </li>
          <li>
            <NavLink to="/presenter">Presenter</NavLink>
          </li>
        </ul>
      </nav> */}
    </header>
  );
}
