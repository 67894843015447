//#region IMPORTS
import React, { useEffect, useState } from "react";
import "../styles/servingCalculator.css";
import axios from "axios";

//#endregion

export default function ServingCalculator() {
  const [categoryList, setCategoryList] = useState([]);
  const [containerCategory, setContainerCategory] = useState();
  const [containerWeight, setContainerWeight] = useState();
  const [totalWeight, setTotalWeight] = useState();
  const [servingCount, setServingCount] = useState(7);

  useEffect(() => {
    axios
      .get("/api/servingCalculator")
      .then(function (response) {
        setCategoryList(response.data);
        setContainerCategory(0);
        setContainerWeight(response.data[0].items[0].weight);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [true]);

  function containerCategory_onChange(e) {
    const value = e.target.value;
    setContainerCategory(value);
    setContainerWeight(categoryList[value].items[0].weight);
  }

  function calculateServings(servings) {
    if (!servings || !totalWeight || !containerWeight) return "";

    const servingWeight = Math.floor(
      (totalWeight - containerWeight) / servings
    );

    return servingWeight > 0 ? servingWeight : "";
  }

  return (
    // <div id="wrapper" style={{ backgroundImage: `url(${background})` }}>
    <div id="serving-calculator-wrapper">
      <h1>Serving calculator</h1>

      <label>
        Container type:
        <select
          value={containerCategory}
          onChange={containerCategory_onChange}
          className="margin-0-10"
        >
          {categoryList.map((category, categoryIndex) => {
            return (
              <option value={categoryIndex} key={category.name + categoryIndex}>
                {category.name}
              </option>
            );
          })}
        </select>
      </label>

      <label>
        Container:
        <select
          value={containerWeight}
          onChange={(e) => setContainerWeight(e.target.value)}
          className="margin-0-10"
        >
          {categoryList.length > 0 &&
            categoryList[containerCategory].items.map(
              (container, containerIndex) => {
                return (
                  <option
                    value={container.weight}
                    key={container.name + containerIndex}
                  >
                    {container.name}
                  </option>
                );
              }
            )}
        </select>
        {containerWeight && `${containerWeight} g`}
      </label>

      <label>
        Total weight:
        <input
          type="number"
          value={totalWeight}
          onChange={(e) => setTotalWeight(e.target.value)}
          className="margin-0-10 four-digits"
          min={containerWeight}
          max="9999"
        />
        g
      </label>

      <table>
        <thead>
          <tr>
            <th>Serving</th>
            <th>Weight</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>{calculateServings(1)}</td>
          </tr>
          <tr>
            <td>2</td>
            <td>{calculateServings(2)}</td>
          </tr>
          <tr>
            <td>3</td>
            <td>{calculateServings(3)}</td>
          </tr>
          <tr>
            <td>4</td>
            <td>{calculateServings(4)}</td>
          </tr>
          <tr>
            <td>5</td>
            <td>{calculateServings(5)}</td>
          </tr>
          <tr>
            <td>6</td>
            <td>{calculateServings(6)}</td>
          </tr>
          <tr>
            <td>
              <input
                type="number"
                value={servingCount}
                onChange={(e) => setServingCount(e.target.value)}
                className="two-digits"
                min="7"
                max="99"
              />
            </td>
            <td>{calculateServings(servingCount)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
