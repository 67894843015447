import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import { isMobile } from "react-device-detect";
import Nav from "../components/nav";
import Login from "../pages/login";
import ServingCalculator from "../pages/servingCalculator";

const Routes = () => {
  const { token } = useAuth();

  const Root = () => {
    return (
      <div
        id="main-wrapper"
        className={!isMobile ? "browser-view" : "mobile-view"}
      >
        <Nav />
        <div id="content-wrapper">
          <Outlet />
        </div>
      </div>
    );
  };
  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <Root />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/",
          element: <ProtectedRoute />,
          children: [
            {
              path: "/",
              element: <ServingCalculator />,
            },
          ],
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: <Login />,
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
